import { Controller } from '@hotwired/stimulus'
import Trix from 'trix'
const { lang } = Trix.config

// Connects to data-controller="trix"
export default class extends Controller {
    static targets = ['editor', 'foregroundColorPicker', 'backgroundColorPicker']

    connect () {
        document.addEventListener('trix-before-initialize', () => {
            this.initTrix()
        })
    }

    initTrix () {
        //Trix.config.blockAttributes.heading1.tagName = 'h1'
        Trix.config.toolbar.getDefaultHTML = this.getDefaultHTML.bind(this)
        this.addHeadingButtonConfig()
        this.addForegroundButtonConfig()
        this.addBackgroundButtonConfig()
        this.addTextAlignButtonConfig()
    }

    openForegroundColorPicker () {
        this.foregroundColorPickerTarget.click()
    }

    openBackgroundColorPicker () {
        this.backgroundColorPickerTarget.click()
    }

    foregroundColorChanged () {
        this.editorTarget.editor.activateAttribute('foregroundColor', this.foregroundColorPickerTarget.value)
    }

    backgroundColorChanged () {
        this.editorTarget.editor.activateAttribute('backgroundColor', this.backgroundColorPickerTarget.value)
    }
    addHeadingButtonConfig(){
        Trix.config.lang.heading1="Heading 1"
        Trix.config.lang.heading2="Heading 2"
        Trix.config.lang.heading3="Heading 3"
        Trix.config.lang.paragraph="Paragraph"
        Trix.config.blockAttributes.heading2={tagName:"h2",terminal:!0,breakOnReturn:!0,group:!1}
        Trix.config.blockAttributes.heading3={tagName:"h3",terminal:!0,breakOnReturn:!0,group:!1}
        Trix.config.blockAttributes.paragraph={tagName:"p",terminal:!0,breakOnReturn:!0,group:!1}
    }
    addForegroundButtonConfig () {
        Trix.config.textAttributes.foregroundColor = {
            styleProperty: 'color',
            inheritable: true
        }
    }

    addBackgroundButtonConfig () {
        Trix.config.textAttributes.backgroundColor = {
            styleProperty: 'backgroundColor',
            inheritable: true
        }
    }

    addTextAlignButtonConfig () {
        Trix.config.lang.alignLeft="Align Left"
        Trix.config.lang.alignCenter="Align Center"
        Trix.config.lang.alignRight="Align Right"
        Trix.config.lang.alignJustify="Align Justify"
        Trix.config.blockAttributes.alignLeft = {
            tagName: 'align-left',
            nestable: true,
            exclusive: true,
        };
        Trix.config.blockAttributes.alignCenter = {
            tagName: 'align-center',
            nestable: true,
            exclusive: true,
        };

        Trix.config.blockAttributes.alignRight = {
            tagName: 'align-right',
            nestable: true,
            exclusive: true,
        };
        Trix.config.blockAttributes.alignJustify = {
            tagName: 'align-justify',
            nestable: true,
            exclusive: true,
        };
    }

    getDefaultHTML () {
        return `<div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
        ${this.foregroundColorButtons}
        ${this.backgroundColorButtons}
      </span>
      ${this.textAlignButtons}
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-2" data-trix-attribute="heading2" title="${lang.heading2}" tabindex="-1">${lang.heading2}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-3" data-trix-attribute="heading3" title="${lang.heading3}" tabindex="-1">${lang.heading3}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-paragraph" data-trix-attribute="paragraph" title="${lang.paragraph}" tabindex="-1">${lang.paragraph}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${lang.code}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
        
      </span>
      <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
      </span>
      <span class="trix-button-group-spacer"></span>
      <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>`
    }

    get foregroundColorButtons () {
        return `<input type="color" style="width:0;height:0;padding:0;margin-top:20px;visibility:hidden"
                   data-trix-target="foregroundColorPicker" data-action="trix#foregroundColorChanged">
            <button type="button" class="trix-button" data-action="click->trix#openForegroundColorPicker" title="Text color">
              <span class="icon"><i class="fas fa-palette fa-lg"></i></span>
            </button>`
    }

    get backgroundColorButtons () {
        return `<input type="color" style="width:0;height:0;padding:0;margin-top:20px;visibility:hidden"
                   data-trix-target="backgroundColorPicker" data-action="trix#backgroundColorChanged">
            <button type="button" class="trix-button" data-action="click->trix#openBackgroundColorPicker" title="Text background color">
              <span class="icon"><i class="fas fa-fill-drip fa-lg"></i></span>
            </button>`
    }

    get textAlignButtons () {
        return `<span class="trix-button-group trix-button-group--alignment-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="${lang.alignLeft}" tabindex="-1">{$lang.alignLeft}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="${lang.alignCenter}" tabindex="-1">{$lang.alignCenter}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="${lang.alignRight}" tabindex="-1">{$lang.alignRight}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-justify" data-trix-attribute="alignJustify" title="${lang.alignJustify}" tabindex="-1">{$lang.alignJustify}</button>
    </span>`
    }
}







