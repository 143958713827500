// app/javascript/controllers/announcement_modal_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    console.log("AnnouncementModalController connected");
    this.showModalIfNecessary();
  }

  showModalIfNecessary() {
    const announcements = document.querySelectorAll('.announcement');
    let showModal = false;

    announcements.forEach((announcement) => {
      const announcementId = announcement.dataset.id;
      if (!this.getCookie(`announcement_${announcementId}`)) {
        showModal = true;
      }

    });

    if (showModal) {
      this.openModal();

      this.modalTarget.addEventListener('hidden.bs.modal', () => {
        announcements.forEach((announcement) => {
          const announcementId = announcement.dataset.id;
          this.setCookie(`announcement_${announcementId}`, 'true', 1);
        });
      });
    }
  }

  openModal() {
    $('#announcementModal').modal('show');
  }

  closeModal() {
    $('#announcementModal').modal('hide');
  }

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
